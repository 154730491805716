module.exports = {
  // Function to get the url of the profile photo
  getUserPhoto(uid, size = '', short_id = null) {
    return `https://firebasestorage.googleapis.com/v0/b/${process.env.VUE_APP_FIREBASE_STORAGEBUCKET}/o/User%2F${uid}%2FProfile%2F${size == '' ? 'photo' : `thumb@${size}_photo`}?alt=media${short_id ? `&shortId=${short_id}` : ''}`;
  },

  sendWhatsapp(phone_number) {
    let message = this.to_send_message;
    this.to_send_message = '';

    if (['iPhone', 'iPad', 'iPod'].includes(navigator.platform)) {
      const win = window.open(`https://wa.me/${phone_number}?text=${encodeURI(message)}`, '_top');
      return win.focus();
    }

    window.open(`https://wa.me/${phone_number}?text=${encodeURI(message)}`);
  },

  // Function to calculate distance of two coordinates
  calculateDistance(start, end) {
    let lat1 = start.lat || start._latitude;
    let lng1 = start.lng || start._longitude;
    let lat2 = end.lat || end._latitude;
    let lng2 = end.lng || end._longitude;

    let R = 6371;
    let dLat = (lat2 - lat1) * (Math.PI / 180);
    let dlng = (lng2 - lng1) * (Math.PI / 180);

    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dlng / 2) *
      Math.sin(dlng / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;

    return d * 1000; // in meters
  },

  // Function to geocode address
  geocode(address) {
    let full_link = "https://maps.googleapis.com/maps/api/geocode/json?address=" + encodeURIComponent(address) + `&region=ie&key=${process.env.VUE_APP_GMAPS_KEY}`;
    
    return fetch(full_link).then(resp => {
      return resp.json();
    });    
  },

  getIPAddress() {
    let link = "https://api.ipify.org?format=json";

    return fetch(link).then(res => res.json());
  },


  // Fnctions to check datatypes
  isType(datatype, value) {
    switch (datatype) {
      case "string":
        return typeof value === "string" || value instanceof String;

      case "number":
        return typeof value === "number" && isFinite(value);

      case "array":
        return value && typeof value === "object" && value.constructor === Array;

      case "function":
        return typeof value === "function";

      case "object":
        return value && typeof value === "object" && value.constructor === Object;

      case "undefined":
        return typeof value === "undefined";

      case "null":
        return value === null;

      case "boolean":
        return typeof value === "boolean";

      case "regex":
        return value && typeof value === "object" && value.constructor === RegExp;

      case "date":
        return value instanceof Date;

      case "latlng":
        return (value && typeof value === "object" && value.constructor === Object) && (
          value.lat && typeof value.lat === "number" && isFinite(value.lat) && value.lng && typeof value.lng === "number" && isFinite(value.lng));

      default:
        return false;
    }
  },

  // Get autocomplete
  placesAutocomplete(input, session_token, types = 'address') {
    let full_link = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${encodeURIComponent(input)}&key=${process.env.VUE_APP_GMAPS_KEY}${session_token ? `&sessiontoken=${session_token}` : ''}&types=${types}`
    
    return fetch(full_link).then(resp => {
      return resp.json();
    });  
  },

  generateShortID() {
    return Math.random()
      .toString(36)
      .substr(2, 256);
  },

  getFullDate(date = {}) {
    var d = new Date(),
        month = '' + (date.month || (d.getMonth() + 1)),
        day = '' + (date.day || d.getDate()),
        year = (date.year || d.getFullYear());

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  },

  format_car_reg_plate(val) {
    let _new = [];
    let _old = val.replace(/ /g, "").toUpperCase().split('');

    for (let i = 0; i < _old.length; i++) {
      if (i == 0) {
          _new.push(_old[i]);
          continue;
        }

      if (/^\d$/.test(_old[i]) !== /^\d$/.test(_old[i-1])) {
        _new.push(' ');
      }

      _new.push(_old[i]);
    }

    return _new.join('');
  },

  validateEmail(email) {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  getOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let platform = window.navigator.platform;

    let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    let iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'MacOS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  },

  isPWA() {
    return window.matchMedia('(display-mode: standalone)').matches;
  },

  titleize(sentence) {
    if (!sentence.split) return sentence;
    var _titleizeWord = function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
      result = [];
    sentence.split(" ").forEach(function (w) {
      result.push(_titleizeWord(w));
    });
    return result.join(" ");
  },

  daysAgo(date) {
    let timeago = Date.now() - date;
    return timeago / (24 * 60 * 60 * 1000);
  },

  sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }
}
